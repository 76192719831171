import useSWRImmutable from 'swr/immutable';

import { get, post } from 'utils/apiWrapper';
import {
  PRODUCTS_LIST_ROUTE,
  PRODUCT_ROUTE,
  PRODUCT_ROUTE_LIVE,
  CART,
  PRODUCT_CREATE_ORDER,
  PRODUCT_CANCEL_ORDER,
  PRODUCT_GET_ORDER_INFO,
  CHECKOUT_INFO,
  PRODUCT_COLLECTIONS,
  PRODUCTS_CAMPAIGN,
  PRODUCT_SIZE_GUIDE,
  ADD_TO_BAG,
  RETRIEVE_BAG,
  FIND_AVAIL_STORES,
  DELETE_FROM_BAG,
  NOTIFY_ME,
  CHECK_GIFTCARD,
  PRODUCTS_RECOMMENDED,
  ORDER_REJECT_1,
  ORDER_REJECT_2,
  CART_CHANGE_SIZE,
  DELIVERY_POINTS,
  ORDER_SEND_MAIL,
  ORDER_MODIFY_SIZE_1,
  ORDER_MODIFY_SIZE_2,
  CHECK_PRODUCT_IN_WISHLIST,
  SEND_INVOICE,
  CREATE_OFFLINE_ORDER,
  CREATE_CONTINGENCY_ORDER,
  COUNTRIES_IPAD,
  GET_RETURN_LABEL,
  POST_TAKEBACK,
  GET_TAKEBACK_CREDIT,
  GET_OTHER_COLORS,
  GET_DROPPOINT_URL,
  CROSS_SELLING,
  REVIEW_DELIVERY,
  GRID_PROPS,
  GET_ROKU_STOCK,
} from 'utils/routes';
import {
  targetsMap,
  DEFAULT_LIMIT,
  formatProducts,
  getRealID,
  handleStorage,
  COOKIES,
  getCookie,
  eraseCookie,
  prepareHeadersFromContext,
  handleOriginByCookie,
  setCookie,
  getIsMobileSSR,
} from 'utils/helpers';

import { AUTO_BONDS } from 'utils/constants/cookies';
import { IS_CAMPER } from 'utils/constants/system';
import { trackCaptchaSuccess } from 'utils/dataLayers';
import { combineTwoShoesPrices, normalizeProductImages } from 'utils/product';
import { getUtmParams } from 'utils/utm';
import { useLocale } from 'hooks';
import { logError } from 'utils/error';

export const sendInvoice = async (payload) => {
  const response = await post({
    path: SEND_INVOICE,
    data: payload,
  });

  if (response) {
    const { data } = response;
    if (data && data.status === 'OK') {
      return data;
    }
  }
  throw new Error('KO');
};

export const getGridProps = ({ context, locale, slug, cache }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    const searchParams = new URLSearchParams({
      profile: locale,
      slug,
      cache,
    });

    const path = `${GRID_PROPS}?${searchParams.toString()}`;
    get({
      path,
      headers,
    })
      .then(({ data }) => {
        if (data.status !== 'OK') {
          reject(`${path} => Error ${data.status} ${data?.errors ? JSON.stringify(data.errors) : ''}`);
        } else {
          resolve(data);
        }
      })
      .catch(reject);
  });

export const getProducts = ({ context, lang, target, page, filters, sort, limit = DEFAULT_LIMIT, product, isMobile, voucherDiscount, seoContent, gridId }) =>
  new Promise((resolve, reject) => {
    const targetRealID = getRealID('target', target);
    const familyRealID = getRealID('product', product);
    const headers = prepareHeadersFromContext(context);

    const searchParams = new URLSearchParams({
      profile: lang,
      targetId: targetRealID,
      family: familyRealID,
    });

    if (filters && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(([key, value]) => {
        searchParams.append(key, value);
      });
    }
    if (sort) {
      searchParams.append('order', sort);
    }
    if (page !== undefined) {
      searchParams.append('pager.offset', page);
      searchParams.append('pager.limit', limit);
    }
    if (isMobile !== undefined && isMobile === true) {
      searchParams.append('device', 'mobile');
    }
    if (voucherDiscount !== undefined && voucherDiscount.length > 0) {
      searchParams.append('voucher', voucherDiscount);
    }
    if (seoContent === true) {
      searchParams.append('seoContent', true);
    }
    if (gridId) {
      searchParams.append('gridId', gridId);
    }

    const path = `${PRODUCTS_LIST_ROUTE}?${searchParams.toString()}`;

    get({ path, headers })
      .then(({ data }) => {
        const {
          status,
          products,
          availableFilters,
          total,
          currentList,
          currentListId,
          camperlab,
          title,
          description,
          urlCanonical,
          seoContent: receivedSeoContent,
          rewalk,
          relatedCollectionId,
          urlRelatedCollection,
          urlCanonicalGTM,
        } = data;

        if (status !== 'OK') {
          reject(`${path} => Error ${status} ${data?.errors ? JSON.stringify(data.errors) : ''}`);
        } else {
          resolve({
            products,
            total,
            availableFilters,
            currentList,
            currentListId,
            camperlab,
            title,
            description,
            urlCanonical,
            seoContent: receivedSeoContent,
            rewalk,
            relatedCollectionId,
            urlRelatedCollection,
            urlCanonicalGTM,
          });
        }
      })
      .catch(reject);
  });

export const getProduct = ({ context, lang, target, productCode }) =>
  new Promise((resolve, reject) => {
    let targetRealID = null;

    Object.keys(targetsMap).forEach((targetKey) => {
      if (targetsMap[targetKey].includes(target)) {
        targetRealID = targetKey;
      }
    });

    const country_price_query = context?.query?.country_price;
    const country_price_cookie = context?.req?.cookies[COOKIES.COUNTRY_PRICE];
    const country_price = country_price_query || country_price_cookie;
    let path = `${PRODUCT_ROUTE}?profile=${lang}&gender=${targetRealID}&material=${productCode}&seoContent=true`;

    if (country_price) {
      path = `${path}&country_price=${country_price}`;
    }

    const headers = prepareHeadersFromContext(context);
    get({
      path,
      headers,
    })
      .then(({ data }) => {
        const { status, productSheet, productBetter, seoContent } = data;
        if (status !== 'OK') {
          reject(`${path} => Error ${status} ${data?.errors ? JSON.stringify(data.errors) : ''}`);
        } else {
          resolve({ status, productSheet, productBetter, seoContent });
        }
      })
      .catch(reject);
  });

export const getProductLive = ({ context, lang, target, productCode, voucherDiscount }) =>
  new Promise((resolve, reject) => {
    const path = `${PRODUCT_ROUTE_LIVE}`;
    const headers = prepareHeadersFromContext(context);
    const dataBody = {
      profile: lang,
      gender: target,
      material: productCode,
    };
    if (voucherDiscount !== undefined && voucherDiscount.length > 0) {
      dataBody.voucher = voucherDiscount;
    }
    post({
      path,
      headers,
      data: dataBody,
    })
      .then(({ data }) => {
        const { status, productSheetLive } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({ status, productSheetLive });
        }
      })
      .catch(reject);
  });

export const getProductCollection = ({ context, lang, ids }) =>
  new Promise((resolve, reject) => {
    const path = `${PRODUCT_COLLECTIONS}?profile=${lang}&filter.id=${ids.join(',')}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status, products, errors } = data;
        if (status === 'OK') {
          resolve(products);
        } else {
          reject(errors);
        }
      })
      .catch(reject);
  });

export const getReviews = ({ context, lang, productCode, offset, sortField, sortType, filter, langList }) =>
  new Promise((resolve, reject) => {
    const i18nString = langList; // para no cambiarlo en el path
    const passKey = IS_CAMPER ? 'ca6JviuvigXXPa5VhlSKKDKEAqRo2kb0KkGfb72gGPdfg' : 'caQmurQvx3wq6cMaj35ZRFZQ98Eo62Rb6tsTbJefSGUX8';
    const diplayCode = IS_CAMPER ? '19298-es_es' : '22318-en_gb';

    // lo hago así y le meto un replace después que si no es un cristo
    let path = `?
passkey=${passKey}&
apiversion=5.5&
displaycode=${diplayCode}&
resource.q0=products&
filter.q0=id:eq:${productCode}&
stats.q0=reviews&
filteredstats.q0=reviews&
filter_reviews.q0=isratingsonly:false&
filter_reviews.q0=contentlocale:eq:${i18nString}&
filter_reviewcomments.q0=contentlocale:eq:${i18nString}&
resource.q1=reviews&
filter.q1=productid:eq:${productCode}&
filter.q1=isratingsonly:false&
filter.q1=contentlocale:eq:${i18nString}&
sort.q1=${sortField}:${sortType}&
stats.q1=reviews&
filteredstats.q1=reviews&
include.q1=authors,products,comments&
filter_reviews.q1=contentlocale:eq:${i18nString}&
filter_reviewcomments.q1=contentlocale:eq:${i18nString}&
filter_comments.q1=contentlocale:eq:${i18nString}&
limit.q1=8&
offset.q1=${offset}&
limit_comments.q1=3
`;

    if (filter !== undefined && Array.isArray(filter) && filter.length > 0) {
      path = `${path}&filter.q1=rating:eq:${filter.join(',')}`;
    }

    path = path?.replace(/(\r\n|\n|\r)/gm, '');

    const base = 'https://api.bazaarvoice.com/data/batch.json';

    get({ base, path })
      .then(async ({ data }) => {
        const { BatchedResultsOrder, BatchedResults, HasErrors, Errors } = data;

        if (HasErrors) {
          reject(Errors);
        } else {
          const firstReviews = BatchedResults?.q1;

          if (firstReviews !== undefined && firstReviews.TotalResults !== 0) {
            const { ProductsOrder } = firstReviews.Includes;
            const { Results } = firstReviews;
            const ids = ProductsOrder.filter((item) => item !== productCode);
            let collectionResponse = [];
            if (ids.length > 0) {
              try {
                collectionResponse = await getProductCollection({
                  context,
                  lang,
                  ids,
                });
              } catch (error) {
                collectionResponse = [];
              }
            }
            for (let i = 0; i < Results.length; i++) {
              const reviewItem = Results[i];
              const { ProductId } = reviewItem;
              const originalCollection = collectionResponse.filter((item) => item.id === ProductId)[0];

              if (originalCollection !== undefined) {
                reviewItem.Origin = originalCollection.name;
              }
            }
          }

          resolve({ BatchedResultsOrder, BatchedResults });
        }
      })
      .catch(reject);
  });

export const getCart = ({ context, lang, products, bondValue, tax, hashOrderId, employeeId, withPaymentMethods, cookies, origin }) =>
  new Promise((resolve, reject) => {
    const path = CART;
    let headers = prepareHeadersFromContext(context);
    if (process.env.ENVIRONMENT === 'local') {
      const clientId = getCookie(COOKIES.CLIENT);
      if (!headers) {
        headers = {
          [COOKIES.CLIENT]: clientId,
        };
      } else if (!headers[COOKIES.CLIENT]) {
        headers[COOKIES.CLIENT] = clientId;
      }
    }

    const productsFormated = products.map((product) => ({
      id: product.code || product.productId || product.id,
      shoppingCartId: String(product.id),
      size: product.size || '',
      gender: product.target || '',
    }));

    const autoVoucher = (cookies === undefined ? getCookie(COOKIES.AUTO_BONDS) : cookies[COOKIES.AUTO_BONDS]) === AUTO_BONDS.APPLY;
    const payload = {
      profile: lang,
      products: productsFormated,
      voucher: bondValue || '',
      employeeId: employeeId || '',
      tax,
      hashOrderId,
      forceSAP: true,
      withPaymentMethods,
      autoVoucher,
    };

    if (typeof window !== 'undefined' && window?.document?.cookie) {
      payload.workstation = getCookie(COOKIES.WORKSTATION);
      payload.embeded = getCookie(COOKIES.APP_IPAD);
      payload.tpvStoreId = getCookie(COOKIES.TPV_STORE_ID);
      payload.tpvEmployeeId = getCookie(COOKIES.TPV_EMPLOYEE_ID);
      payload.country_price = getCookie(COOKIES.COUNTRY_PRICE);
    } else if (context && context.req) {
      const {
        [COOKIES.APP_IPAD]: embeded,
        [COOKIES.TPV_STORE_ID]: tpvStoreId,
        [COOKIES.TPV_EMPLOYEE_ID]: tpvEmployeeId,
        [COOKIES.WORKSTATION]: workstation,
        [COOKIES.COUNTRY_PRICE]: country_price,
      } = context?.req?.cookies;
      if (embeded !== undefined) {
        payload.embeded = embeded;
      }
      if (tpvEmployeeId !== undefined) {
        payload.tpvEmployeeId = tpvEmployeeId;
      }
      if (tpvStoreId !== undefined) {
        payload.tpvStoreId = tpvStoreId;
      }
      if (workstation !== undefined) {
        payload.workstation = workstation;
      }
      if (country_price !== undefined) {
        payload.country_price = country_price;
      }
    }

    if (payload.embeded !== undefined && payload.tpvStoreId !== undefined) {
      // appmode forceSap always true
      // no tengo acceso a isBlank desde mi rama ni al cambio de estructura de Dani hasta que lo suyo no se suba a master
      payload.forceSap = true;
    }
    if (origin === 'voucher') {
      payload.applyVoucher = true;
    }

    post({
      path,
      headers,
      data: payload,
    })
      .then(({ data }) => {
        const { status } = data;
        if (status !== 'OK') {
          reject(data);
        } else {
          resolve({
            ...data,
          });
        }
      })
      .catch(reject);
  });

export const createOrder = ({
  lang,
  products,
  shipmentData,
  paymentData,
  bondValue,
  checkoutExpressHashId,
  shoppingCartHashId,
  expressShoppingHashId,
  storeId,
  paymentInfo,
  newsletter,
  isMobile,
  appliedGiftcard,
  paypalPlus,
  employeeId,
  csEmployeeId,
  embeded,
  workstation,
  tpvStoreId,
  tpvEmployeeId,
  shippingCost,
  totalImport,
  captchaToken,
  newsletterDiscount,
}) =>
  new Promise((resolve, reject) => {
    // use body
    const path = PRODUCT_CREATE_ORDER;
    const billingAddress = {};

    const originFields = handleOriginByCookie();
    const utmParams = getUtmParams();

    if (storeId === undefined && Object.keys(shipmentData).includes('billing-name')) {
      Object.keys(shipmentData).forEach((item) => {
        if (item.startsWith('billing-')) {
          billingAddress[item.replace('billing-', '')] = shipmentData[item];
          delete shipmentData[item];
        }
      });
    }

    const dataToSend = {
      profile: lang,
      voucher: bondValue,
      ...shipmentData,
      products: formatProducts(products),
      ...paymentData,
      checkoutExpressHashId,
      shoppingCartHashId,
      expressShoppingHashId,
      paymentInfo,
      newsletter,
      device: isMobile !== undefined && isMobile === true ? 'mobile' : null,
      paypalPlus,
      employeeId,
      csEmployeeId,
      embeded,
      workstation,
      tpvStoreId,
      tpvEmployeeId,
      shippingCost,
      totalImport,
      newsletterDiscount,
      ...originFields,
      ...utmParams,
    };

    if (Object.keys(billingAddress).length !== 0) {
      dataToSend.billingAddress = billingAddress;
    }

    if (storeId) {
      dataToSend.pickupStoreId = storeId;
      dataToSend.billingAddress = { ...shipmentData };
    }

    if (appliedGiftcard) {
      const { giftCardNum, giftCardPin } = appliedGiftcard;
      dataToSend.giftCardNum = giftCardNum;
      dataToSend.giftCardPin = giftCardPin;
    }
    if (captchaToken) {
      dataToSend.captchaToken = captchaToken;
    }

    post({
      path,
      data: dataToSend,
    })
      .then(({ data }) => {
        const { status, order, expressShopping, encodedReference } = data;
        const userAlreadySubscribed = data.userAlreadySubscribed !== undefined ? data.userAlreadySubscribed : false;

        // si el pedido tiene token de captcha checkear si ha ido bien o mal y lanzar evento
        if (captchaToken) {
          const errorCode = status === 'KO' && data.errors && data.errors[0] ? data.errors[0].code : null;
          trackCaptchaSuccess(errorCode !== 'E_CAPTCHA_ERROR');
        }

        if (status !== 'OK') {
          reject(data);
        } else {
          handleStorage('removeItem', 'giftCards');
          if (order?.adyenResponse?.resultCode !== 'Pending') {
            eraseCookie(COOKIES.EMPLOYEE_ID);
          }
          resolve({
            status,
            order,
            userAlreadySubscribed,
            expressShopping,
            encodedReference,
          });
        }
      })
      .catch((reason) => {
        reject(reason);
      });
  });

export const cancelOrder = ({ lang, mail, order, reason }) =>
  new Promise((resolve, reject) => {
    const path = PRODUCT_CANCEL_ORDER;
    const dataToSend = {
      profile: lang,
      mail,
      order,
      reason,
    };
    post({
      path,
      data: dataToSend,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        delete data.audit;
        resolve(data);
      })
      .catch(reject);
  });

export const getOrderInfo = ({ context, lang, email, locator, offlineReference, store, fiscalYear, includeReturnLabel }) =>
  new Promise((resolve, reject) => {
    const path = offlineReference ? `${PRODUCT_GET_ORDER_INFO}?profile=${lang}` : `${PRODUCT_GET_ORDER_INFO}?profile=${lang}&email=${email}&locator=${locator}`;
    const payload =
      !offlineReference ?
        {
          profile: lang,
          email,
          locator,
          includeReturnLabel,
        }
      : {
          profile: lang,
          offlineReference,
          store,
          fiscalYear,
          includeReturnLabel,
        };

    let headers = prepareHeadersFromContext(context);
    if (headers === null) {
      headers = {};
    }
    headers['content-type'] = 'application/json';

    post({
      path,
      headers,
      data: payload,
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({
            ...data,
          });
        }
      })
      .catch(reject);
  });

export const reviewDelivery = async (payload) => {
  const response = await post({
    path: REVIEW_DELIVERY,
    data: payload,
  });

  if (response) {
    const { data } = response;
    if (data && data.status === 'OK') {
      return data;
    }
  }
  throw new Error('KO');
};

export const getCheckoutInfo = ({
  context,
  lang,
  products,
  voucher,
  tax,
  withPaymentMethods,
  embeded,
  workstation,
  employeeId,
  csEmployeeId,
  tpvStoreId,
  tpvEmployeeId,
}) =>
  new Promise((resolve, reject) => {
    const path = `${CHECKOUT_INFO}`;
    const headers = prepareHeadersFromContext(context);
    const { cookies } = context?.req;
    const countryPrice = cookies[COOKIES.COUNTRY_PRICE];

    const payload = {
      profile: lang,
      products,
      voucher,
      tax,
      withPaymentMethods,
      embeded,
      workstation,
      employeeId,
      csEmployeeId,
      tpvStoreId,
      tpvEmployeeId,
      country_price: countryPrice,
    };

    post({
      path,
      headers,
      data: payload,
    }).then((response) => {
      const { data } = response;
      if (data.status === 'OK') {
        delete data.audit;
        delete data.status;
        resolve(data);
      } else {
        reject(data.errors);
      }
    });
  });

export const getCampaign = ({ context, lang, camp, family, pager, target, filter, timestamp, voucherDiscount }) =>
  new Promise((resolve, reject) => {
    let path = `${PRODUCTS_CAMPAIGN}?profile=${lang}&camp=${camp}&family=${family}&pager=${pager}&target=${target}`;
    if (filter) {
      path = `${path}&filter.collection=${filter}`;
    }
    if (timestamp) {
      path = `${path}&timestamp=${Date.now()}`;
    }
    if (voucherDiscount !== undefined && voucherDiscount.length > 0) {
      path = `${path}&voucher=${voucherDiscount}`;
    }
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        if (data.campaign === undefined) {
          reject(data);
        } else {
          resolve(data.campaign);
        }
      })
      .catch(reject);
  });

export const getSizeGuide = ({ context, lang, material }) =>
  new Promise((resolve, reject) => {
    const path = `${PRODUCT_SIZE_GUIDE}?profile=${lang}&material=${material}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        if (data.sizeGuide !== undefined) {
          resolve(data.sizeGuide);
          return;
        }
        if (data.sizeGuideClothes !== undefined) {
          resolve(data.sizeGuideClothes);
          return;
        }
        reject(data);
      })
      .catch(reject);
  });

export const addToBag = (payload) =>
  new Promise((resolve, reject) => {
    const path = ADD_TO_BAG;

    if (window.location) {
      // como solo se llama a addToBag desde el cliente siempre debería haber window.location
      const queryParams = new URLSearchParams(window.location.search);
      const country_price_query = queryParams.get('country_price');
      const country_price_cookie = getCookie(COOKIES.COUNTRY_PRICE);
      const country_price = country_price_query || country_price_cookie;

      if (country_price) {
        setCookie(COOKIES.COUNTRY_PRICE, country_price, 1);
        payload.country_price = country_price;
      }
    }

    const clientId = getCookie(COOKIES.CLIENT);
    let headers = undefined;
    if (clientId) {
      headers = {
        [COOKIES.CLIENT]: clientId,
      };
    }
    // para el carrito abandonado se envia el usrhash si existe.
    const usrhashCookie = getCookie(COOKIES.SELLIGENT_USRHASH);
    if (usrhashCookie) {
      payload.usrHash = usrhashCookie;
    }
    post({
      path,
      headers,
      data: payload,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });

export const retrieveBag = ({ context, payload }) =>
  new Promise((resolve, reject) => {
    const path = RETRIEVE_BAG;
    const headers = prepareHeadersFromContext(context);

    post({
      path,
      headers,
      data: payload,
    })
      .then(({ data }) => {
        if (!data.shoppingCart || data.shoppingCart.length === 0) {
          console.error('Error retrieving bag', path, headers, payload, data);
        }
        resolve(data);
      })
      .catch((error) => {
        console.error('Error retrieving bag', path, headers, payload, error);
        reject(error);
      });
  });

export const cartChangeSize = (payload) =>
  new Promise((resolve, reject) => {
    const path = CART_CHANGE_SIZE;
    post({
      path,
      data: payload,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });

export const deleteFromBag = (payload) =>
  new Promise((resolve, reject) => {
    const path = DELETE_FROM_BAG;
    post({
      path,
      data: payload,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });

export const findStores = ({ lang, productId, longitude, latitude, size, takeback }) =>
  new Promise((resolve, reject) => {
    const path = FIND_AVAIL_STORES;
    const payload = {
      profile: lang,
      productId,
      longitude,
      latitude,
      size,
      takeback: takeback || false,
    };
    if (takeback) {
      delete payload.productId;
    }
    post({
      path,
      data: payload,
    }).then(({ data }) => {
      if (data.status === 'OK' && data.stores) {
        resolve(data.stores);
      } else {
        reject(data.status);
      }
    });
  });

export const notifyMe = ({ profile, productId, name, email, size, newsletter, notifymeForSize }) =>
  new Promise((resolve, reject) => {
    const path = NOTIFY_ME;
    post({
      path,
      data: {
        profile,
        productId,
        size,
        name,
        email,
        newsletter,
        notifymeForSize,
      },
    }).then(({ data }) => {
      if (data.status === 'OK') {
        resolve(data);
      } else {
        reject(data.status);
      }
    });
  });

export const checkGiftCard = (payload) =>
  new Promise((resolve, reject) => {
    const path = CHECK_GIFTCARD;
    post({
      path,
      data: payload,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });

export const getProductsRecommended = ({ lang, productCode, ia, target, forModal = false, targetIdUrl, voucher }) =>
  new Promise((resolve, reject) => {
    let path = `${PRODUCTS_RECOMMENDED}?profile=${lang}&targetIdUrl=${targetIdUrl}&material=${productCode}&useIA=${ia === 'true'}${target ? `&gender=${target}` : ''}${voucher ? `&voucher=${voucher}` : ''}`;

    if (forModal) {
      path = `${path}&forModal=true`;
    }

    get({ path })
      .then(({ data }) => {
        const { status, recommendeds, listName } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          // inject from param for analytics
          recommendeds.map((re) => (re.url = `${re.url}`));
          resolve({ status, recommendeds, listName });
        }
      })
      .catch(reject);
  });

export const rejectOrder1 = ({ lang, email, reference, reason, subReason, originalStore, originalExpedition, rejectProducts, region }) =>
  new Promise((resolve, reject) => {
    const path = ORDER_REJECT_1(reference);

    post({
      path,
      data: {
        profile: lang,
        reference,
        email,
        reason,
        subReason,
        originalStore,
        originalExpedition,
        rejectProducts,
        region,
      },
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { status, products, shipmentsCosts, pickup, stores, cities } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({
            status,
            products,
            shipmentsCosts,
            pickup,
            stores,
            cities,
          });
        }
      })
      .catch(reject);
  });

export const rejectOrder2 = ({
  lang,
  order,
  reason,
  subReason,
  rejectProducts,
  shippingMethodId,
  useNewAddress,
  returnDate,
  applicant,
  expeditionCenterType,
}) =>
  new Promise((resolve, reject) => {
    const { reference, store, expedition, countryId, version } = order;
    const path = ORDER_REJECT_2(reference);
    const rqData = {
      profile: lang,
      reference,
      ...applicant,
      country: countryId,
      originalStore: store,
      originalExpedition: expedition,
      reason,
      subReason,
      rejectProducts,
      shippingMethodId,
      useNewAddress,
      returnDate,
      version,
      expeditionCenterType,
    };
    if (order.receiver?.regionCode) {
      rqData.region = order.receiver.regionCode;
    }
    post({
      path,
      data: rqData,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { status, errors } = data;
        if (status === 'KO') {
          let errorMessage = 'KO';
          if (errors && errors.length) {
            errorMessage = `${errors[0].code}: ${errors[0].message}`;
          }
          reject(errorMessage);
        } else {
          resolve({
            status,
            ...data,
          });
        }
      })
      .catch(reject);
  });

export const getDeliveryPoints = ({ context, profile, deliveryType, countryId, city, zipCode }) =>
  new Promise((resolve, reject) => {
    const path = DELIVERY_POINTS;
    let headers = prepareHeadersFromContext(context);
    if (headers === null) {
      headers = {};
    }
    headers['content-type'] = 'application/json';

    console.log('[INCIDENCIA DEBUG] payload for get delivery points', {
      profile,
      deliveryType,
      countryId,
      city: city || '',
      zipCode,
    });

    post({
      path,
      headers,
      data: {
        profile,
        deliveryType,
        countryId,
        city: city || '',
        zipCode,
      },
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          const { errors } = data;
          if (errors) {
            reject(errors);
          } else {
            reject(status);
          }
        } else {
          resolve({
            ...data,
          });
        }
      })
      .catch(reject);
  });

export const orderSendMail = ({ profile, email, locator }) =>
  new Promise((resolve, reject) => {
    const path = ORDER_SEND_MAIL;

    post({
      path,
      data: {
        profile,
        email,
        locator,
      },
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({
            ...data,
          });
        }
      })
      .catch(reject);
  });

export const modifySize1 = ({ profile, email, reference, reason, subReason, originalStore, originalExpedition, rejectProducts, region }) =>
  new Promise((resolve, reject) => {
    const path = ORDER_MODIFY_SIZE_1(reference);

    post({
      path,
      data: {
        profile,
        reference,
        email,
        reason,
        subReason,
        originalStore,
        originalExpedition,
        rejectProducts,
        region,
      },
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { status, products, shipmentsCosts, pickup, defaultPickup, paymentMethods, productShipmentCost, banks } = data;
        if (status !== 'OK') {
          reject(status);
        } else {
          resolve({
            status,
            products,
            shipmentsCosts,
            pickup,
            defaultPickup,
            paymentMethods,
            productShipmentCost,
            banks,
          });
        }
      })
      .catch(reject);
  });

export const modifySize2 = (payload) =>
  new Promise((resolve, reject) => {
    const { reference } = payload;
    const path = ORDER_MODIFY_SIZE_2(reference);

    post({
      path,
      data: payload,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(({ data }) => {
        const { status, message, orderId, returnId, adyenResponse, pdfUrl, redirectUrl, resp3ds2, respEissueUrl, errors } = data;
        if (status === 'KO') {
          let errorMessage = 'KO';
          if (errors && errors.length) {
            errorMessage = `${errors[0].code}: ${errors[0].message}`;
          }
          reject(errorMessage);
        } else {
          resolve({
            status,
            message,
            orderId,
            returnId,
            adyenResponse,
            pdfUrl,
            redirectUrl,
            resp3ds2,
            respEissueUrl,
            errors,
          });
        }
      })
      .catch(reject);
  });

export const checkProductInWishList = ({ profile, productId }) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem('token');
    post({
      path: CHECK_PRODUCT_IN_WISHLIST,
      data: {
        profile,
        productId,
      },
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          reject(status);
        } else {
          resolve(data.wishlist);
        }
      })
      .catch(reject);
  });

export const createOfflineOrder = ({ profile, payload }) =>
  new Promise((resolve, reject) => {
    post({
      path: CREATE_OFFLINE_ORDER,
      data: {
        profile,
        ...payload,
      },
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          reject(status);
        } else {
          resolve(data);
        }
      })
      .catch(reject);
  });

export const createContingencyOrder = ({
  lang,
  products,
  shipmentData,
  paymentData,
  bondValue,
  checkoutExpressHashId,
  shoppingCartHashId,
  expressShoppingHashId,
  storeId,
  paymentInfo,
  newsletter,
  isMobile,
  appliedGiftcard,
  paypalPlus,
  employeeId,
  csEmployeeId,
  embeded,
  workstation,
  tpvStoreId,
  tpvEmployeeId,
  newsletterDiscount,
}) =>
  new Promise((resolve, reject) => {
    // use body
    const path = CREATE_CONTINGENCY_ORDER;
    const billingAddress = {};

    const originFields = handleOriginByCookie();
    const utmParams = getUtmParams();

    if (storeId === undefined && Object.keys(shipmentData).includes('billing-name')) {
      Object.keys(shipmentData).forEach((item) => {
        if (item.startsWith('billing-')) {
          billingAddress[item.replace('billing-', '')] = shipmentData[item];
          delete shipmentData[item];
        }
      });
    }

    const dataToSend = {
      profile: lang,
      voucher: bondValue,
      ...shipmentData,
      products: formatProducts(products),
      ...paymentData,
      checkoutExpressHashId,
      shoppingCartHashId,
      expressShoppingHashId,
      paymentInfo,
      newsletter,
      device: isMobile !== undefined && isMobile === true ? 'mobile' : null,
      paypalPlus,
      employeeId,
      csEmployeeId,
      embeded,
      workstation,
      tpvStoreId,
      tpvEmployeeId,
      newsletterDiscount,
      ...originFields,
      ...utmParams,
    };

    if (Object.keys(billingAddress).length !== 0) {
      dataToSend.billingAddress = billingAddress;
    }

    if (storeId) {
      dataToSend.pickupStoreId = storeId;
      dataToSend.billingAddress = { ...shipmentData };
    }

    if (appliedGiftcard) {
      const { giftCardNum, giftCardPin } = appliedGiftcard;
      dataToSend.giftCardNum = giftCardNum;
      dataToSend.giftCardPin = giftCardPin;
    }

    post({
      path,
      data: dataToSend,
    })
      .then(({ data }) => {
        const { status, order, expressShopping, encodedReference } = data;
        const userAlreadySubscribed = data.userAlreadySubscribed !== undefined ? data.userAlreadySubscribed : false;
        if (status !== 'OK') {
          reject(data);
        } else {
          handleStorage('removeItem', 'giftCards');
          eraseCookie(COOKIES.EMPLOYEE_ID);
          resolve({
            status,
            order,
            userAlreadySubscribed,
            expressShopping,
            encodedReference,
          });
        }
      })
      .catch(reject);
  });

export const getIpadCountries = ({ context, profile }) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);

    get({ path: `${COUNTRIES_IPAD}?profile=${profile}`, headers })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            resolve(data.countries);
          }
        }
      })
      .catch(reject);
  });

export const getReturnLabel = ({ profile, email, locator }) =>
  new Promise((resolve, reject) => {
    post({
      path: GET_RETURN_LABEL,
      data: {
        profile,
        email,
        locator,
      },
    })
      .then((response) => {
        if (response?.data?.status === 'OK') {
          resolve(response.data);
        }

        // PRUEBA POPUP ERROR
        reject(' ');

        // reject(response);
      })
      .catch(reject);
  });

export const postTakeback = (formData) =>
  new Promise((resolve, reject) => {
    post({
      path: POST_TAKEBACK,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        const { status } = data;

        if (status !== 'OK') {
          reject(status);
        } else {
          resolve(data);
        }
      })
      .catch(reject);
  });

export const getTakebackCredit = (profile) =>
  new Promise((resolve, reject) => {
    get({ path: `${GET_TAKEBACK_CREDIT}?profile=${profile}` })
      .then((response) => {
        if (response) {
          const { data } = response;
          if (data && data.status === 'OK') {
            resolve(data);
          }
        }
      })
      .catch(reject);
  });

export const getOtherColors = ({ context, lang, target, id, voucher }) =>
  new Promise((resolve, reject) => {
    const path = `${GET_OTHER_COLORS}?profile=${lang}&material=${id}&gender=${target}${voucher ? `&voucher=${voucher}` : ''}`;
    const headers = prepareHeadersFromContext(context);

    get({ path, headers })
      .then(({ data }) => {
        const { status, products, listName } = data;

        if (status === 'OK' && products?.length > 0) {
          const productsToReturn = products.map((re) => {
            re.url = `${re.url}?from=${id}`;
            return re;
          });
          resolve({ products: productsToReturn, listName });
        } else {
          reject(data);
        }
      })
      .catch(reject);
  });

/**
 * @typedef {object} Droppoint
 * @property {string} title
 * @property {string} url
 * @property {string} description
 * @property {string} buttonText
 * @property {string} imgUrl
 */
/**
 * @typedef {object} DroppointResponse
 * @property {string} droppointUrl
 * @property {Droppoint[]} droppoints
 */
/**
 *
 * @param {object} context
 * @param {string} profile
 * @param {string|null} type
 * @returns {DroppointResponse}
 */
export async function getDroppointsInfo(context, profile, type = null) {
  const headers = prepareHeadersFromContext(context);
  const path = `${GET_DROPPOINT_URL}?profile=${profile}${type ? `&type=${type}` : ''}`;

  const { data } = await get({ path, headers });

  const { status, droppointUrl, droppoints } = data;
  if (status === 'OK') {
    return { droppointUrl, droppoints };
  }
  throw data;
}

export const getCrossSelling = (context, profile, productId) =>
  new Promise((resolve, reject) => {
    const headers = prepareHeadersFromContext(context);
    const path = `${CROSS_SELLING}?profile=${profile}&productId=${productId}`;

    get({ path, headers })
      .then(({ data }) => {
        const { status, recommendeds } = data;

        if (status === 'OK' && recommendeds) {
          resolve(recommendeds);
        } else {
          reject(data);
        }
      })
      .catch(reject);
  });

export const getRokuStock = async ({ context, profile, size }) => {
  const headers = prepareHeadersFromContext(context);

  return post({
    path: GET_ROKU_STOCK,
    headers,
    data: {
      profile,
      size,
    },
  })
    .then(({ data }) => {
      const { status, noStockProducts } = data;
      if (status === 'OK') {
        return noStockProducts;
      }

      return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });
};

export async function getSunneiProductData({ sunneiInfo, locale, context, target }) {
  // necesitamos 2 cosas, traer toda la info de los skus de sunnei y toda la info de los productSheetLive y hacer combinaciones de precios y tallas
  const sunneiSkus = Array.from(new Set(sunneiInfo?.all ?? []));
  if (!sunneiSkus.length) {
    return {
      error: true,
      message: 'No products found',
    };
  }

  // necesitamos crear promesas con cada sku
  const sheetPromises = [];
  const livePromises = [];
  sunneiSkus.forEach((sku) => {
    sheetPromises.push(
      getProduct({
        context,
        lang: locale,
        target,
        productCode: sku,
        targetIdUrl: target,
      }),
    );
    livePromises.push(
      getProductLive({
        context,
        lang: locale,
        target,
        productCode: sku,
      }),
    );
  });

  // llamamos a todas las promesas a la vez

  const [sheetResponses, liveResponses] = await Promise.all([Promise.all(sheetPromises), Promise.all(livePromises)]);
  const isMobile = getIsMobileSSR(context);
  // en un producto normal lo que se cambia son los sizes y los voucher prices.
  const newSunneiData = sheetResponses.reduce((acc, sheet, index) => {
    let live = liveResponses[index];
    // check ids
    if (!live || live.productSheetLive.id !== sheet.productSheet.id) {
      const foundLiveWithSameId = liveResponses.find((l) => l.productSheet.id === sheet.productSheet.id);
      if (foundLiveWithSameId) {
        live = foundLiveWithSameId;
      } else {
        return acc;
      }
    }
    if (sheet.status === 'OK' && live.status === 'OK') {
      acc[sheet.productSheet.id] = {
        ...sheet.productSheet,
        sizes: live.productSheetLive?.sizes ?? [],
        images: normalizeProductImages({ data: sheet.productSheet.views, isMobile }),
        voucherPrices: live?.productSheetLive?.voucherPrices ?? null,
      };
    }
    return acc;
  }, {});
  const aggregateSizes = liveResponses.reduce((acc, live) => {
    if (live.status === 'OK' && Array.isArray(live?.productSheetLive?.sizes)) {
      live.productSheetLive.sizes.forEach((size) => {
        // primero si existe el size y el actual tiene menos stock que el anterior, no lo metemos
        if (acc.has(size.value) && acc.get(size.value).quantity > size.quantity) {
          return;
        }
        acc.set(size.value, size);
      });
    }
    return acc;
  }, new Map());

  const firstShoeSheet = newSunneiData[sunneiInfo.shoeOne];
  const secondShoeSheet = newSunneiData[sunneiInfo.shoeTwo];

  const combinedPrices = combineTwoShoesPrices({
    shoeOnePrice: firstShoeSheet.prices,
    shoeTwoPrice: secondShoeSheet.prices,
  });
  const json = JSON.parse(
    JSON.stringify({
      data: newSunneiData,
      sizes: Array.from(aggregateSizes.values()),
      price: combinedPrices,
      skus: sunneiSkus,
    }),
  );
  return json;
}

export function useLookStock({ primarySku, skus, target }) {
  const { locale } = useLocale();
  return useSWRImmutable(['look', primarySku], async () => {
    try {
      let responses = await Promise.all(
        skus.map((sku) =>
          getProductLive({
            lang: locale,
            target,
            productCode: sku,
          }),
        ),
      );
      responses = responses.filter((r) => r.status === 'OK');
      return Object.fromEntries(responses.map((r) => [r.productSheetLive.id, { sizes: r.productSheetLive.sizes, prices: r.productSheetLive.prices }]));
    } catch (error) {
      logError('useLookStock', error);
      return {};
    }
  });
}
