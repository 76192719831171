import { retrieveBag } from 'actions/products';
import {
  COOKIES, getCookie, setCookie, getLang, getMarket, eraseCookie,
} from './helpers';
import { BAG_EXPIRATION_DAYS, AUTO_BONDS } from './constants/cookies';
import { IS_CAMPER, IS_NNORMAL } from './constants/system';
import { dataLayerHandleEvent } from './dataLayers';
import { parseBagItemsEcommerceGA4 } from './gtmUtils';
import { isBlank } from './StringUtils';

// 30 min: 30 * 60 * 1000 = 1_800_000
const ABANDONED_CART_INTERVAL = 1_800_000;

function isBagAbandoned() {
  const cartUpdateDate = getCookie(COOKIES.BAG_DATE);
  if (!cartUpdateDate) return false;
  const updateDate = new Date(cartUpdateDate);
  if (!updateDate) return false;
  const difference = Date.now() - Date.parse(updateDate);
  return difference > ABANDONED_CART_INTERVAL;
}

function updateBagDate() {
  setCookie(COOKIES.BAG_DATE, new Date().toISOString(), BAG_EXPIRATION_DAYS);
}

function setBagCookies({ hash, market }) {
  setCookie(COOKIES.BAG, encodeURI(hash), BAG_EXPIRATION_DAYS);
  setCookie(COOKIES.BAG_MARKET, market, BAG_EXPIRATION_DAYS);
  updateBagDate();
}

const retrieveBagHandler = async ({
  locale,
  config,
  setBag,
  setBagLength,
  setPromoLabel,
  setPromoLabelType,
  setBagHasMemberOnlyProduct,
  setDisabledProducts,
}) => {
  try {
    const lang = getLang(locale);
    const market = getMarket(locale);
    const bagHash = getCookie(COOKIES.BAG);
    const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
    const voucher = getCookie(COOKIES.BONDS) || '';
    const voucherDiscount = IS_CAMPER && autoVoucher && (autoVoucher === AUTO_BONDS.APPLY);
    const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
    const showFreeShipping = config?.show_free_shipping || false;
    const showGiftPurchase = config?.show_gift_purchase || false;

    let updatedPromoLabel = null;
    let updatedPromoLabelType = 'progress';
    let currentBag = [];

    if (bagHash && cookiesMarket === market) {
      const payload = {
        hashOrderId: bagHash,
        profile: {
          countryId: market,
          languageId: lang,
        },
      };

      if (voucherDiscount || (IS_NNORMAL && !isBlank(voucher))) {
        payload.voucher = voucher;
      }

      const result = await retrieveBag({ payload });

      if (showFreeShipping) {
        updatedPromoLabel = currentBag?.freeShippingLabel || null;
      }
      if (showGiftPurchase) {
        updatedPromoLabel = currentBag?.gwplabel || currentBag?.gwplabelObtained || currentBag?.freeShippingLabel || null;
        if (currentBag?.gwplabelObtained && currentBag?.gwplabelObtained !== '') {
          updatedPromoLabelType = 'obtained';
        }
      }
      if (setDisabledProducts && !isBlank(result.blockedSkus)) {
        setDisabledProducts(result.blockedSkus);
      }

      if (result?.shoppingCart) {
        currentBag = result.shoppingCart;
      }
    }

    if (cookiesMarket !== market) {
      eraseCookie(COOKIES.BAG);
      eraseCookie(COOKIES.BAG_DATE);
      eraseCookie(COOKIES.BAG_MARKET);
      eraseCookie(COOKIES.SHIPMENT);
      eraseCookie(COOKIES.EMPLOYEE_ID);
      eraseCookie(COOKIES.PACKSTATION);
      eraseCookie(COOKIES.IS_CTR);
      eraseCookie(COOKIES.ZIP);
    }

    setBag(currentBag);
    setBagLength(currentBag.length);
    setPromoLabel(updatedPromoLabel);
    setPromoLabelType(updatedPromoLabelType);

    setBagHasMemberOnlyProduct(currentBag.filter((product) => product.membersOnly === true).length > 0);

    dataLayerHandleEvent({
      event: 'cart_sync',
      cart: {
        items: parseBagItemsEcommerceGA4(currentBag),
      },
    });

    return currentBag;
  } catch (e) {
    console.error('Init bag error', e);
    return null;
  }
};

export {
  isBagAbandoned,
  setBagCookies,
  updateBagDate,
  retrieveBagHandler,
};
