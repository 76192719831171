/* eslint-disable prefer-destructuring */
import { useMemo } from 'react';
import { defaultLocale } from 'utils/translations';
import { useRouter } from 'next/router';

export function useLocale() {
  const { locale } = useRouter();

  const parsedLocale = useMemo(() => {
    const parsed = {
      locale: null,
      country: null,
      language: null,
    };
    // si el locale no es valido(en_US), retorna parsed con los valores a null
    if (!(/^[a-z]{2}_[A-Z]{2}$/).test(locale)) {
      console.warn('useLocale: invalid locale', locale);
      const splitDefault = defaultLocale.split('_');
      parsed.locale = defaultLocale;
      parsed.country = splitDefault[1];
      parsed.language = splitDefault[0];
    }
    const split = locale.split('_');
    parsed.locale = locale;
    parsed.country = split[1];
    parsed.language = split[0];
    return parsed;
  }, [ locale ]);

  return parsedLocale;
}
