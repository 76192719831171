/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { parseProductsEcommerceGA4 } from './gtmUtils';

export const addLazyLoadIntersector = () => {
  var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));
  if ('IntersectionObserver' in window) {
    var lazyVideoObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              if (videoSource.dataset.src) {
                videoSource.src = videoSource.dataset.src;
              }
            }
          }
          video.target.load();
          video.target.loop = true;
          video.target.autoplay = true;
          video.target.classList.remove('lazy');
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
};

export const addLazyLoadIntersectorLanding = (gtmProps) => {
  var lazyTrackables = [].slice.call(document.querySelectorAll('div.ant-row[data-trackingga4*="campaign|row"]'));
  if ('IntersectionObserver' in window) {
    var lazyTrackablesObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (trackable) {
        if (trackable.isIntersecting) {
          const trackedTargetData = trackable.target?.dataset?.trackingga4?.split('|');
          if (trackedTargetData.length > 2) {
            const promotionId = trackedTargetData[0];
            const datalayerViewItemList = window?.dataLayer && window?.dataLayer?.filter((datalayer) => datalayer?.event === 'view_item_list');
            const alreadyInDatalayer = datalayerViewItemList && datalayerViewItemList.length && datalayerViewItemList[datalayerViewItemList.length - 1].id === promotionId;
            if (datalayerViewItemList.length === 0 || !alreadyInDatalayer) {
              const collection = gtmProps?.campaign?.collections?.find((col) => col.id === promotionId);
              const products = gtmProps?.campaign?.collections?.filter((col) => col.id === promotionId)[0].products;

              const eventGA4 = {
                event: 'view_item_list',
                id: promotionId,
                nonInteraction: '1',
                ecommerce: {
                  currency: products.length ? products[0].prices?.currency : '',
                  items: parseProductsEcommerceGA4(products, promotionId, collection?.listName),
                },
              };
              dataLayerHandleEvent(eventGA4);
            }
          }
          lazyTrackablesObserver.unobserve(trackable.target);
        }
      });
    });

    lazyTrackables.forEach(function (lazyTrackable) {
      lazyTrackablesObserver.observe(lazyTrackable);
    });
  }
};

export const addLazyLoadIntersectorSlider = (gtmProps, currency) => {
  var lazyTrackables = [].slice.call(document.querySelectorAll('h3[data-trackingga4*="|slider"]'));
  if ('IntersectionObserver' in window) {
    var lazyTrackablesObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (trackable) {
        if (trackable.isIntersecting) {
          const trackedTargetData = trackable.target?.dataset?.trackingga4.split('|');
          const sliderListName = trackedTargetData[0];
          const datalayerViewItemList = window?.dataLayer && window?.dataLayer?.filter((datalayer) => datalayer?.event === 'view_item_list');
          const alreadyInDatalayer = datalayerViewItemList && datalayerViewItemList.length && datalayerViewItemList[datalayerViewItemList.length - 1].id === sliderListName;
          if (datalayerViewItemList.length === 0 || !alreadyInDatalayer) {
            const gtmData = gtmProps[sliderListName];

            if (gtmData) {
              const { products, listName } = gtmData;
              const eventGA4 = {
                event: 'view_item_list',
                id: sliderListName,
                nonInteraction: '1',
                ecommerce: {
                  currency,
                  items: parseProductsEcommerceGA4(products, sliderListName, listName),
                },
              };
              dataLayerHandleEvent(eventGA4);
            }
          }
          lazyTrackablesObserver.unobserve(trackable.target);
        }
      });
    });

    lazyTrackables.forEach(function (lazyTrackable) {
      lazyTrackablesObserver.observe(lazyTrackable);
    });
  }
};
