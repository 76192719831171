import { useContext, useEffect } from 'react';

import { HeadSEO } from 'components/base';
import { i18nContext } from 'context/i18n';

import styles from 'styles/ErrorPage.module.css';
import { IS_CAMPER } from 'utils/constants/system';

const imageProps = IS_CAMPER ? {
  src: '/assets-new/logo.jpg', alt: 'Camper logo', height: 76, width: 150,
} : { src: '/assets-new/logo-nnormal.svg', alt: 'Nnormal logo', height: 76 };

const InternalErrorPage = () => {
  const { t } = useContext(i18nContext);
  useEffect(() => {
    const hash = window && window.location.hash ? window.location.hash : null;
    if (window.location.pathname.includes('/content/') && hash) {
      console.log('Hash doesnt exists');
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
      window.location.reload();
    }
  }, []);
  return (
    <div>
      <HeadSEO title={IS_CAMPER ? 'CAMPER' : 'NNORMAL'} description={ IS_CAMPER ? 'Camper 500 error page' : 'Nnormal 500 error page'}/>
      <header className={styles.header}>
        <img {...imageProps} />
      </header>
      <aside className={styles.aside}>
        <img src="/assets-new/error500.gif" alt="Error 500" width={500} height={500} />
      </aside>
      <p className={styles.title}>{t('generico', 'error.500.titulo', 'WE’RE IN A TANGLE')}</p>
      <p className={styles.text}>{t('generico', 'error.500.descripcion', 'Our server has developed some unexpected knots (that’s what happens when you work with so many lace-up shoes). Could you come back later to see if our engineers have managed to unravel them? Thank you; we’re sorry for the inconvenience')}</p>
    </div>
  );
};

InternalErrorPage.i18nPage = 'InternalErrorPage';

export default InternalErrorPage;
