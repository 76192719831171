import { useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import {
  string, bool, array, node,
} from 'prop-types';

import FloatLabel from 'components/base/floatLabel';

import { IS_NNORMAL } from 'utils/constants/system';

import styles from './style.module.css';

const FormInput = ({
  name, floatLabel, required, rules, helper, children, dependencies, className, label, noStyle, width,
  tooltip, tooltipText, market,
}) => {
  const [ focus, setFocus ] = useState(false);

  const handleOnFocus = () => {
    setFocus(true);
  };

  const handleOnBlur = () => {
    setFocus(false);
  };
  /*
    Respecto a que no funcione onBlur podríamos de alguna
    manera observar el onChange, llamar al validator por
    nuestra cuenta después de blur y cambiar la prop validateStatus
    en base al resultado
  */

  let inputContent = children;

  if (IS_NNORMAL) {
    if (name === 'city' && market !== 'US') {
      inputContent = <Input placeholder="" />;
    }
  }
  if (tooltip && tooltipText) {
    return (
      <Tooltip
        placement="bottom"
        title={<span dangerouslySetInnerHTML={{ __html: tooltipText }}></span>}
        overlayClassName={'tooltip'}
        arrowPointAtCenter
      >
        <Form.Item
          name={name}
          required={required}
          rules={rules}
          validateTrigger={[ 'onChange', 'onBlur' ]} // no funciona onBlur
          hasFeedback
          className={styles.formItem}
          help={helper && focus ? <div dangerouslySetInnerHTML={{ __html: helper }}/> : null}
          dependencies={dependencies}
          label={label}
          noStyle={noStyle}
        >
          {
          floatLabel === '' ?
            inputContent :
            (
              <FloatLabel style={{ width }} className={className} label={floatLabel} name={name} onFocus={handleOnFocus} onBlur={handleOnBlur}>
                { inputContent }
              </FloatLabel>
            )
          }
        </Form.Item>
      </Tooltip>
    );
  }
  return (
    <Form.Item
      name={name}
      required={required}
      rules={rules}
      validateTrigger={[ 'onChange' ]} // no funciona onBlur
      hasFeedback
      className={styles.formItem}
      help={helper && focus ? <div dangerouslySetInnerHTML={{ __html: helper }}/> : null}
      dependencies={dependencies}
      label={label}
      noStyle={noStyle}
    >
      {
      floatLabel === '' ?
        inputContent :
        (
          <FloatLabel style={{ width }} className={className} label={floatLabel} name={name} onFocus={handleOnFocus} onBlur={handleOnBlur}>
            { inputContent }
          </FloatLabel>
        )
      }
    </Form.Item>
  );
};

FormInput.defaultProps = {
  required: false,
  rules: [],
  floatLabel: '',
  dependencies: [],
  noStyle: false,
  market: '',
};

FormInput.propTypes = {
  name: string.isRequired,
  required: bool,
  floatLabel: string,
  rules: array,
  className: string,
  helper: string,
  children: node.isRequired,
  dependencies: array,
  label: string,
  noStyle: bool,
  width: string,
  tooltip: bool,
  tooltipText: string,
  market: string,
};

export default FormInput;
