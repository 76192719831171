import {
  cloneElement, useEffect, useRef, useContext,
} from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import { i18nContext } from 'context/i18n';
import { userContext } from 'context/user';
import dataLayer, { dataLayerTrackable } from 'utils/dataLayers';
import {
  getLang, getMarket, getIsMobile, loadsCamperONE,
} from 'utils/helpers';

import { DEFAULT_SEO } from 'utils/constants/seo';
import { IS_NNORMAL } from 'utils/constants/system';

const faviconHref = IS_NNORMAL ? '/assets-new/favicon-nnormal.ico' : '/assets-new/favicon.ico';
export default function HeadSEO({
  title, description, scripts, onLoad, pageName, gtmProps, extraTags, children,
}) {
  const { locale, profileData } = useContext(i18nContext);
  const { setIsGTMReady } = useContext(userContext);
  const market = getMarket(locale);
  const router = useRouter();
  const isMobile = getIsMobile('992px');
  const urlRef = useRef(router.asPath);
  const initGTM = useRef(false);
  const gtmPropsToUse = { ...gtmProps, profileData, locale };

  const checkPatterson = () => {
    const [ target ] = document.getElementsByTagName('header');
    if (target !== undefined) {
      const observer = new MutationObserver((mutationList) => {
        const lastMutation = mutationList[mutationList.length - 1];
        const [ tnm ] = lastMutation.addedNodes;
        if (tnm) {
          dataLayerTrackable(document.getElementsByClassName('trackable'));
        }
      });
      observer.observe(target, { childList: true, subtree: true });
    }
  };

  const handleDataLayer = async () => {
    let { gtm } = router.query;

    if (gtm === undefined) {
      gtm = true;
    } else {
      gtm = JSON.parse(gtm);
    }

    if (process.browser && gtm && pageName !== null) {
      if (initGTM.current === false) {
        await dataLayer(pageName, '', isMobile, getLang(router.locale), getMarket(router.locale), gtmPropsToUse);
        dataLayerTrackable(document.getElementsByClassName('trackable'));
        checkPatterson();
        setIsGTMReady(true);
        initGTM.current = true;
      }
    }
  };

  useEffect(() => {
    if (router.asPath !== urlRef.current) {
      initGTM.current = false;
      handleDataLayer();
    }
  }, [ router.asPath ]);

  useEffect(() => {
    const handleInitLayer = async () => {
      if (scripts.length > 0) {
        const [ target ] = document.getElementsByTagName('head');
        const observer = new MutationObserver((mutationList) => {
          const lastMutation = mutationList[mutationList.length - 1];
          const [ lastScript ] = lastMutation.addedNodes;

          if (lastScript !== undefined) {
            // Comprobar que esto era solo para CamperONE viejo y si es así eliminarlo
            if (lastScript.src && loadsCamperONE(lastScript.src)) {
              if (pageName === 'CamperONE') {
                lastScript.addEventListener('load', async () => {
                  await onLoad();
                  await handleDataLayer();
                });
              }
            }
          }
        });

        if (![ 'CamperONE', null ].includes(pageName)) {
          await handleDataLayer();
        }

        observer.observe(target, { attributes: false, childList: true, subtree: true });
      } else {
        await handleDataLayer();
      }
    };

    handleInitLayer();
  }, []);

  // const prerenderHref = `/${router.locale}/search`;

  return (
    <Head>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, minimum-scale=1, maximum-scale=1, user-scalable=0"/>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta key="og:price:currency" property="og:price:currency" content={profileData.currencySap} />,
      <link key="favicon" rel="icon" href={faviconHref} />
      {children}
      { market === 'TH' ? <meta name="google-site-verification" content="8w3V9ffPLFo4juXKxiluIcMZ2EcBjfg53nqMqLQTKHc"/> : null}
      { IS_NNORMAL && market === 'KR' ? <meta name="naver-site-verification" content="211941b431d1ccfe3418824f8a97807ec155cb91" /> : null }
      { /* <link rel="prerender" href={prerenderHref} /> */ }
      {extraTags.map((tag) => tag)};
      {scripts.map((item) => cloneElement(
        item, {
          id: item.props?.id ? item.props?.id : `script-${item.key}`,
          name: `script-${item.key}`,
          async: item.props?.async || false,
          defer: item.props?.defer || false,
        },
      ))}
    </Head>

  );
}

HeadSEO.defaultProps = {
  title: DEFAULT_SEO.title,
  description: DEFAULT_SEO.description,
  scripts: [],
  pageName: null,
  onLoad: () => true,
  extraTags: [],
  children: null,
};

HeadSEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pageName: PropTypes.any,
  locale: PropTypes.string,
  scripts: PropTypes.array,
  products: PropTypes.array,
  gtmProps: PropTypes.object,
  onLoad: PropTypes.func,
  extraTags: PropTypes.array,
  children: PropTypes.any,
};
