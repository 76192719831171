import useSWRImmutable from 'swr/immutable';
import { setCookie } from 'nookies';

import { post } from 'utils/apiWrapper';
import { kboixKeys } from 'components/kboix';
import {
  KBOIX_MIDSOLES, KBOIX_RACES, KBOIX_SUGGESTIONS, KBOIX_TIPS, KBOIX_TIPS_FEEDBACK, KBOIX_TIPS_REGISTER, KBOIX_USER, NNORMAL_WORLD_UNSUBSCRIBE,
  NNORMAL_WORLD_OFFLINE_REGISTER,
} from 'utils/routes';
import { COOKIES } from 'utils/helpers';
import { logError } from 'utils/error';

export async function registerOfflineOrder(body) {
  const path = NNORMAL_WORLD_OFFLINE_REGISTER;

  const { data } = await post({
    path,
    data: body,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  if (data.status !== 'OK') {
    throw data;
  }
  return data;
}

const zappBaseUrl = process.env.NEXT_PUBLIC_ZAPP_API_URL ?? 'https://kboix-api-dev.zapp.dev/api';

export async function getSuggestions(body) {
  // this api call is only available on the client side
  if (typeof window === 'undefined') {
    throw new Error('This function is only available on the client side');
  }
  const r = await fetch(`${zappBaseUrl}${KBOIX_SUGGESTIONS}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (!r.ok) {
    throw new Error('Error fetching suggestions');
  }
  const json = await r.json();
  if (!Array.isArray(json)) {
    throw new Error('Error parsing suggestions');
  }

  localStorage.setItem(kboixKeys.suggestions, JSON.stringify({ formData: body, suggestions: json.slice(0, 2) }));
  return json;
}

/**
 * @typedef {Object} KboixMidsole
 * @property {string} id
 * @property {string} description
 * @property {string} name
 * @property {string} image
 * @property {number} durability
 * @property {number} performance
 * @property {number} lightness
 */
export async function fetchMidsoles(url) {
  const r = await fetch(zappBaseUrl + url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!r.ok) {
    return [];
  }
  const data = await r.json();
  if (Array.isArray(data)) {
    return data;
  }
  return [];
}
export function useKboixMidsoles(shouldFetch = true, config) {
  return useSWRImmutable(shouldFetch ? KBOIX_MIDSOLES : null, /** @returns {KboixMidsole[]} */ fetchMidsoles, config);
}

export async function saveFirstRace(body) {
  // this api call is only available on the client side
  if (typeof window === 'undefined') {
    throw new Error('This function is only available on the client side');
  }
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }
  console.log(JSON.stringify(body), `bearer ${token}`);
  console.log(`${zappBaseUrl}${KBOIX_TIPS_REGISTER}`);
  const r = await fetch(`${zappBaseUrl}${KBOIX_TIPS_REGISTER}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!r.ok) {
    throw new Error('Error fetching suggestions');
  }
  setCookie(null, COOKIES.KBOIX_TIPS, 'true', {
    sameSite: 'strict',
  });
}

export async function fetchTips(url) {
  const token = localStorage.getItem('token');

  const urlSplit = url.split('?');
  const baseUrl = urlSplit[0];
  const lang = urlSplit.pop();
  if (!token) {
    return [];
  }

  const r = await fetch(zappBaseUrl + baseUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Locale': lang,
    },
  });
  /** @type KboixTip[] */
  if (!r.ok) {
    if (r.status === 401) {
      window.location.reload();
    }
    return [];
  }
  /** @type KboixTip[] */
  const data = await r.json();

  if (!Array.isArray(data)) {
    return [];
  }
  return data;
}

/**
 * @typedef {Object} KboixTip
 * @property {string} id
 * @property {string} userTipId
 * @property {string} externalId
 * @property {string} title
 * @property {string} link
 * @property {string} text
 * @property {boolean | null} like
 * @property {number} totalLikes
 * @property {number} totalUnlikes
 */
export function useTips(shouldFetch = false, options, locale = 'en') {
  return useSWRImmutable(shouldFetch ? `${KBOIX_TIPS}?${locale}` : undefined, fetchTips, options);
}

export async function sendTipFeedback(id, body) {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }
  const r = await fetch(zappBaseUrl + KBOIX_TIPS_FEEDBACK(id), {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!r.ok) {
    const e = await r.json();
    logError(e);
    if (r.status === 401) {
      window.location.reload();
    }
  }
}
/**
 * @typedef {Object} KboixRace
 * @property {string} id
 * @property {string} name
 * @property {string} date
 * @property {number} type
 */
export function useRaces(shouldFetch = false) {
  return useSWRImmutable(shouldFetch ? KBOIX_RACES : undefined, /** @returns {KboixTip[]} */ async (url) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return [];
    }
    const r = await fetch(zappBaseUrl + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!r.ok) {
      if (r.status === 401) {
        window.location.reload();
      }
      return [];
    }
    const json = await r.json();
    if (!Array.isArray(json)) {
      return json;
    }
    return json;
  });
}

export async function addRace(body) {
  const token = localStorage.getItem('token');
  if (!token) {
    return [];
  }
  const r = await fetch(zappBaseUrl + KBOIX_RACES, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!r.ok) {
    if (r.status === 401) {
      window.location.reload();
    }
    return [];
  }
  const json = await r.json();
  console.log(json);
  return null;
}
/**
 * @param {string} id
 */
export async function deleteRace(id) {
  const token = localStorage.getItem('token');
  if (!token) {
    return [];
  }
  const r = await fetch(`${zappBaseUrl}${KBOIX_RACES}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!r.ok) {
    if (r.status === 401) {
      window.location.reload();
    }
    return [];
  }
  const json = await r.json();
  console.log(json);
  return null;
}

/**
 * @typedef {Object} KboixUser
 * @property {string} id
 * @property {string} email
 * @property {string | null} first_name
 * @property {string | null} last_name
 * @property {string | null} last_period
 * @property {string | null} age
 * @property {number} gender
 * @property {number} goals
 * @property {number} tread
 * @property {number} foot_width
 * @property {number} weight_range
 * @property {boolean} altitude
 * @property {boolean} heat
 * @property {boolean} train_high_carb
 * @property {boolean} train_respiratory
 * @property {boolean} isCompletedForm
*/
export function useKboixUser(shouldFetch = false, config) {
  return useSWRImmutable(shouldFetch ? KBOIX_USER : undefined, /** @returns {KboixUser | null} */ async (url) => {
    const token = localStorage.getItem('token');
    if (!token) {
      return [];
    }
    const r = await fetch(zappBaseUrl + url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!r.ok) {
      if (r.status === 401) {
        window.location.reload();
      }
      return null;
    }
    const json = await r.json();

    return json;
  }, config);
}
/** We need this function to create first this user to avoid creating multiple users in the database */
export async function fetchZappUserOnGetNnormalUser() {
  try {
    const token = localStorage.getItem('token');
    const r = await fetch(zappBaseUrl + KBOIX_USER, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!r.ok) {
      return null;
    }
    const json = await r.json();
    return json;
  } catch (error) {
    return null;
  }
}

export async function saveKboixUser(body) {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }
  const r = await fetch(zappBaseUrl + KBOIX_USER, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  if (!r.ok) {
    if (r.status === 401) {
      window.location.reload();
    }
    const json = await r.json();
    throw json;
  }
  const json = await r.json();
  console.log(json);
  return true;
}

export async function unsubscribeZappNewsletter(ok) {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }
  const r = await fetch(zappBaseUrl + NNORMAL_WORLD_UNSUBSCRIBE, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({ newsletter: ok }),
  });
  if (!r.ok) {
    const e = await r.json();
    logError(e);
    if (r.status === 401) {
      window.location.reload();
    }
    throw e;
  }
}
